import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { S3Constants } from 'src/app/common';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@schneideress/za-ui-controls';
@Component({
  selector: 's3-time-range-control',
  templateUrl: './time-range-control.component.html',
  styleUrl: './time-range-control.component.scss',
})
export class TimeRangeControlComponent {
  @Input() emissionStartYear: number = S3Constants.BASELINE_START_YEAR;
  @Input() timeRanges: any[] = [
    { value: 'alltime', displayText: this.translateService.translate('shared.alltime') },
    //{ value: 'sincebaseline', displayText: 'Since Baseline' },
    { value: 'last3years', displayText: this.translateService.translate('track.last3years') },
    {
      value: 'rolling12months',
      displayText: this.translateService.translate('track.rolling12months'),
    },
    { value: 'yeartodate', displayText: this.translateService.translate('track.yeartodate') },
    { value: 'customrange', displayText: this.translateService.translate('track.customrange') },
  ];
  @Input() baselineYear: number = S3Constants.BASELINE_START_YEAR;
  @Output() dateRangeSelected = new EventEmitter<{
    startDate: Date | string | null;
    endDate: Date | string | null;
    timeRange: string | null;
  }>();

  isDropdownOpen = false;
  selectedOption: any = 'yeartodate';
  selectedLabel: string = 'Select Date Range';
  maxDate: Date = new Date();
  yearList: any;
  startYearList: any;
  endYearList: any;
  previousRange: any = {
    start: '',
    end: '',
  };
  yearInfo: { start: number; end: number | null } = {
    start: new Date().getFullYear(),
    end: new Date().getFullYear(),
  };

  constructor(
    private elRef: ElementRef,
    private datePipe: DatePipe,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setYearData();
    this.updateDateRange();
  }
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  updateDateRange(): void {
    let startDate: Date | string | null = null;
    let endDate: Date | string | null = null;
    let timeRange: string | null = null;
    this.resetYearInfoData();
    this.selectedLabel = this.timeRanges.filter(x => x.value == this.selectedOption)[0].displayText;

    if (this.selectedOption === 'alltime') {
      const today = new Date();
      startDate = new Date(this.emissionStartYear, 0, 1);
      endDate = today;
    } else if (this.selectedOption === 'sincebaseline') {
      const today = new Date();
      startDate = new Date(this.baselineYear, 0, 1);
      endDate = today;
    } else if (this.selectedOption === 'last3years') {
      const today = new Date();
      startDate = new Date(today.getFullYear() - 3, today.getMonth(), today.getDate());
      endDate = today;
    } else if (this.selectedOption === 'rolling12months') {
      const today = new Date();
      startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
      endDate = today;
    } else if (this.selectedOption === 'yeartodate') {
      const today = new Date();
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = today;
    }

    if (startDate != null && endDate != null && endDate > startDate) {
      this.isDropdownOpen = false;
      timeRange = this.selectedOption;
      startDate = this.datePipe.transform(startDate, 'yyyy/MM/dd');
      endDate = this.datePipe.transform(endDate, 'yyyy/MM/dd');
      this.dateRangeSelected.emit({ startDate, endDate, timeRange });
    }
  }

  updateCustomDateRange(): void {
    let startDate: Date | string | null = null;
    let endDate: Date | string | null = null;
    let timeRange: string | null = null;

    if (this.selectedOption === 'customrange') {
      if (this.yearInfo) {
        const today = new Date();
        startDate = new Date(this.yearInfo.start, 0, 1);
        if (this.yearInfo.end == today.getFullYear()) endDate = today;
        else if (this.yearInfo.end !== null) endDate = new Date(this.yearInfo.end, 11, 31);
        this.selectedLabel = this.yearInfo.start + ' - ' + this.yearInfo.end;
      }
    }

    if (startDate != null && endDate != null && endDate > startDate) {
      this.isDropdownOpen = false;
      timeRange = this.selectedOption;
      startDate = this.datePipe.transform(startDate, 'yyyy/MM/dd');
      endDate = this.datePipe.transform(endDate, 'yyyy/MM/dd');
      this.previousRange = { ...this.yearInfo };
      this.dateRangeSelected.emit({ startDate, endDate, timeRange });
    }
  }

  setYearData(): void {
    this.yearList = [];
    this.startYearList = [];
    this.endYearList = [];

    for (let year = this.emissionStartYear; year <= new Date().getFullYear(); year++) {
      this.yearList.push(year);
    }
    this.startYearList = [...this.yearList]; // Start year
    this.validateRange(this.yearInfo.start); // End year
  }
  updateStartYear(startYear: number): void {
    this.yearInfo.start = startYear;
    this.yearInfo.end = null;
    this.validateRange(startYear);
    setTimeout(() => {
      this.yearInfo.end = this.endYearList[0];
    }, 0);
  }

  updateEndYear(endYear: number): void {
    this.yearInfo.end = endYear;
  }

  validateRange(Startyear: number): void {
    this.endYearList = this.yearList.filter((year: number) => year >= Startyear);
  }

  resetYearInfoData(): void {
    this.previousRange = {
      start: '',
      end: '',
    };
    this.yearInfo = {
      start: new Date().getFullYear(),
      end: null,
    };
    this.validateRange(this.yearInfo.start);
    this.yearInfo.end = this.endYearList[0];
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      if (
        this.selectedOption == 'customrange' &&
        (this.yearInfo.start !== this.previousRange.start ||
          this.yearInfo.end !== this.previousRange.end)
      ) {
        this.updateCustomDateRange();
      }
    }
  }
}
