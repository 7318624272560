import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 's3-year-range',
  standalone: false,
  templateUrl: './year-range.component.html',
  styleUrl: './year-range.component.scss',
})
export class YearRangeComponent implements OnInit {
  @Output() yearRangeChange = new EventEmitter<{ start: number; end: number }>();

  @Input() yearInfo: any;
  baseLineYear: any;
  yearList: any;
  currentYear: any = new Date().getFullYear();
  dropdownOpen = false;
  startYearList: any;
  endYearList: any;
  previousRange: any;
  constructor(private currentElement: ElementRef) {}
  setYear(): void {
    this.baseLineYear = this.yearInfo.baseLine;
    this.yearList = [];
    this.startYearList = [];
    this.endYearList = [];

    for (let year = this.yearInfo.fromYear; year <= this.yearInfo.toYear; year++) {
      this.yearList.push(year);
    }
    this.startYearList = [...this.yearList]; // Start year
    this.validateRange(this.yearInfo.yearRange.start); // End year
    this.previousRange = {
      start: this.yearInfo.yearRange.start,
      end: this.yearInfo.yearRange.end,
    };
  }

  ngOnInit(): void {
    this.setYear();
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  updateStartYear(startYear: number): void {
    this.yearInfo.yearRange.start = startYear;
    this.yearInfo.yearRange.end = startYear;
    this.endYearList = [];
    this.validateRange(startYear);
  }

  updateEndYear(endYear: number): void {
    this.yearInfo.yearRange.end = endYear;
  }

  checkAndEmitYearInfo(): void {
    if (this.yearInfo.yearRange.start && this.yearInfo.yearRange.end) {
      if (
        this.yearInfo.yearRange.start !== this.previousRange.start ||
        this.yearInfo.yearRange.end !== this.previousRange.end
      ) {
        this.yearRangeChange.emit({
          start: this.yearInfo.yearRange.start,
          end: this.yearInfo.yearRange.end,
        });
        this.previousRange = { ...this.yearInfo.yearRange };
      }
    }
  }

  validateRange(Startyear: number): void {
    this.yearList.map((year: number) => {
      if (year >= Startyear) {
        this.endYearList.push(year);
      }
    });
  }
  @HostListener('document:click', ['$event'])
  closeDropdown(e: any): void {
    if (e.target) {
      if (
        !this.currentElement.nativeElement?.contains(e.target) &&
        e.target.parentElement &&
        !e.target.parentElement.firstChild.classList?.contains('')
      ) {
        if (this.dropdownOpen) {
          this.dropdownOpen = false;
          this.checkAndEmitYearInfo();
        }
      }
    }
  }
}
