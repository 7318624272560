<div class="col-auto date-range-wrap">
  <div class="dropdown year-dropdown">
    <div class="dropdown-header" (click)="toggleDropdown()">
      <span class="header-label">{{ 'shared.yearrange' | translate }}</span>
      <span class="selected-years">
        {{ yearInfo.yearRange.start }} - {{ yearInfo.yearRange.end }}</span
      >
      <i class="far fa-chevron-down pop-icon"></i>
    </div>
    <div class="dropdown-content" *ngIf="dropdownOpen">
      <span>
        <span class="s3-form-element-label">{{ 'shared.Start' | translate }}</span>
        <s3-dropdown
          [items]="startYearList"
          (selectionChange)="updateStartYear($event)"
          [valuePrimitive]="true"
          [(ngModel)]="yearInfo.yearRange.start"
          [size]="'sm'"></s3-dropdown>
      </span>
      <span>
        <span class="s3-form-element-label">{{ 'shared.end' | translate }}</span>
        <s3-dropdown
          [items]="endYearList"
          (selectionChange)="updateEndYear($event)"
          [valuePrimitive]="true"
          [(ngModel)]="yearInfo.yearRange.end"
          [size]="'sm'"></s3-dropdown>
      </span>
    </div>
  </div>
</div>
