import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { CommonService } from '../../common';
import { S3Constants } from 'src/app/common';
import { TranslateService } from '@schneideress/za-ui-controls';

export interface IUomOption {
  uomId: number;
  uomName: string;
  uomISOCode?: string;
}

export interface IUomValue extends IUomOption {
  value?: number;
}

@Component({
  selector: 's3-uom-value',
  templateUrl: 'uom-value.component.html',
  styleUrls: ['uom-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UOMValueComponent),
      multi: true,
    },
  ],
})
export class UOMValueComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() placeholder = this.translateService.translate('shared.amount');
  @Input() isCurrency = true;
  @Input() dataStream = '';
  @Input() label = '';
  @Input() datastreamMeasurentId = 0;
  @Input() uoms: IUomOption[] = [];
  @Input() validationMessage = '';
  @Input() uomNames: any[] = [];
  @Input() disabled = false;
  @Output() valueChanged = new EventEmitter<IUomValue | null>();
  @Input() acceptNegativeValue = true;
  @Input() maxLength = Number.MAX_SAFE_INTEGER;
  @Input() valueRequired = true;
  @Input() validateInput!: (value: string) => boolean;
  @ViewChild('inputControl') inputControl: NgModel | undefined;

  emissiomUOMs: any = S3Constants.EmissionUOMs;
  selectedData: IUomValue = {
    uomId: 0,
    uomName: '',
    uomISOCode: '',
  };
  showValidationMessage = false;
  dropdownChanged = false;
  dropdownOpened = false;
  valueAdded = false;
  constructor(
    private commonService: CommonService,
    public translateService: TranslateService,
  ) {}

  propagateChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.loadData();
    this.dropdownOpened = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['isCurrency'] &&
      changes['isCurrency'].previousValue != changes['isCurrency'].currentValue
    )
      this.uoms = [];
    this.loadData();
  }

  writeValue(obj: IUomValue): void {
    if (obj) this.selectedData = obj;
    else obj = <IUomValue>{};
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changed(e: any): void {
    this.valueAdded = true;
    if (e.target.value) this.selectedData.value = e.target.value;
    if (this.valueAdded) this.raiseEvent();
  }

  selectionChange(item: IUomOption): void {
    this.dropdownChanged = true;
    this.selectedData.uomId = item.uomId;
    this.selectedData.uomName = item.uomName;
    this.selectedData.uomISOCode = item.uomISOCode;
    if (this.dropdownChanged) this.raiseEvent();
  }

  keyPress(e: any): void {
    if (
      e.keyCode == 190 &&
      this.selectedData?.value &&
      this.selectedData.value.toString().indexOf('.') > -1
    )
      e.preventDefault();
    if (!this.acceptNegativeValue) {
      if (
        [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    } else {
      if (
        [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39) ||
        // Allow: -ve values
        (e.keyCode === 189 && !this.selectedData?.value?.toString())
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }

  validate(): boolean {
    let isValid = true;
    const uomSelected = !!this.selectedData.uomId;
    const valueEntered = !!Number(this.selectedData.value?.toString());

    if (this.valueRequired) {
      isValid = uomSelected && valueEntered;
    } else if (valueEntered) {
      isValid = uomSelected;
    }
    if (this.validateInput != null && this.validateInput != undefined && this.selectedData.value) {
      if (!this.validateInput(this.selectedData.value.toString())) {
        this.showValidationMessage = true;
        return (isValid = false);
      }
    }

    this.showValidationMessage = !isValid;
    return isValid;
  }

  private loadData(): void {
    const uoms: any[] = [];
    if (this.isCurrency) {
      if (this.uoms.length == 0) {
        this.commonService.getCurrency().subscribe((data: any[]) => {
          data.forEach((item: any) => {
            uoms.push({
              uomName: item.CurrencyName,
              uomId: item.CurrencyId,
              uomISOCode: item.CurrencyISOCode,
            });
          });
        });
        this.uoms = uoms;
      }
    } else {
      const uoms: IUomOption[] = [];
      if (this.datastreamMeasurentId) {
        this.commonService.getUOMs(this.datastreamMeasurentId).subscribe((data: any[]) => {
          data.forEach((item: any) => {
            uoms.push({ uomName: item.DisplayText, uomId: item.UomId });
          });
          this.uoms = uoms;
        });
      } else if (this.dataStream) {
        this.commonService.getUOMsByDatastream(this.dataStream).subscribe((data: any[]) => {
          data.forEach((item: any) => {
            uoms.push({ uomName: item.DisplayText, uomId: item.UomId });
          });
          this.uoms = uoms;
        });
      } else if (this.uomNames.length > 0) {
        this.commonService.getUomsByUomNames(this.uomNames).subscribe((data: any[]) => {
          this.uoms = data.map((u: any) => ({
            uomName: u.DisplayText,
            uomId: u.UomId,
            uomGuidXid: u.UomGUIDXId,
          }));
        });
      }
    }
  }

  private raiseEvent(): void {
    if (this.validate()) {
      this.propagateChange(this.selectedData);
      this.valueChanged.emit(this.selectedData);
    } else {
      this.propagateChange(null);
      this.valueChanged.emit(null);
    }
  }
}
