<span class="s3-form-element-label" *ngIf="label.length > 0">{{ label }}</span>
<div class="s3-amount-main" [class.disabled]="disabled">
  <input
    #inputControl="ngModel"
    [(ngModel)]="selectedData.value"
    (keydown)="keyPress($event)"
    [disabled]="disabled"
    class="s3-amount-input"
    [placeholder]="placeholder ? placeholder : ''"
    (change)="changed($event)"
    [maxlength]="maxLength" />
  <kendo-dropdownlist
    class="s3-dropdown-uoms"
    [disabled]="disabled"
    (selectionChange)="selectionChange($event)"
    (blur)="dropdownOpened = true"
    [data]="uoms"
    [textField]="'uomName'"
    [valueField]="'uomId'"
    [(ngModel)]="selectedData">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span *ngIf="dataItem && !emissiomUOMs.includes(dataItem?.['uomName'])">
        {{ dataItem?.['uomName'] }}
      </span>
      <span *ngIf="dataItem && emissiomUOMs.includes(dataItem?.['uomName'])">
        <s3-emission-uom [uom]="dataItem?.['uomName']"></s3-emission-uom>
      </span>
    </ng-template>
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span *ngIf="dataItem && !emissiomUOMs.includes(dataItem?.['uomName'])">
        {{ dataItem?.['uomName'] }}
      </span>
      <span *ngIf="dataItem && emissiomUOMs.includes(dataItem?.['uomName'])">
        <s3-emission-uom [uom]="dataItem?.['uomName']"></s3-emission-uom>
      </span>
    </ng-template>
  </kendo-dropdownlist>
</div>
<s3-text
  class="s3-validation-text ms-2"
  *ngIf="showValidationMessage && (dropdownOpened || selectedData.uomId) && inputControl.touched"
  [type]="'Small'"
  [color]="'var(--s3-color-validation)'"
  >{{ validationMessage }}</s3-text
>
