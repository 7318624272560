<div class="dropdown-container s3-input-sm" [ngClass]="{ open: isDropdownOpen }">
  <div class="dropdown-header d-flex" (click)="toggleDropdown()">
    {{ selectedLabel }}
    <div>
      <span [class.open]="isDropdownOpen"> <i class="fa fa-chevron-down chevron"></i></span>
    </div>
  </div>

  <div class="dropdown-menu justify-content-center" *ngIf="isDropdownOpen">
    @for (range of timeRanges; track range) {
      <div [ngClass]="{ 'selected-option': selectedOption === range.value }" class="py-2">
        <label class="custom-radio-label">
          <input
            class="align-middle custom-radio-input"
            type="radio"
            name="range"
            value="{{ range.value }}"
            [(ngModel)]="selectedOption"
            (change)="updateDateRange()" />
          <span>{{ range.displayText }}</span>
        </label>
      </div>
    }

    <div *ngIf="selectedOption === 'customrange'" class="date-picker" class="year-range-container">
      <span>
        <span class="s3-form-element-label">{{ 'shared.Start' | translate }}</span>
        <s3-dropdown
          [items]="startYearList"
          (selectionChange)="updateStartYear($event)"
          [valuePrimitive]="true"
          [(ngModel)]="yearInfo.start"
          [size]="'sm'"></s3-dropdown>
      </span>
      <span>
        <span class="s3-form-element-label">{{ 'shared.end' | translate }}</span>
        <s3-dropdown
          [items]="endYearList"
          (selectionChange)="updateEndYear($event)"
          [valuePrimitive]="true"
          [(ngModel)]="yearInfo.end"
          [size]="'sm'"></s3-dropdown>
      </span>
    </div>
  </div>
</div>
